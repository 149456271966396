<template>
	<!-- 添加机器人 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<router-link class="btn" to="/robot">{{$t('aside.list')[2]}}</router-link>
				<i class="iconfont2 icon-right_arrow"></i>
				<span>{{$t('robot.addrobot')}}</span>
			</div>

			<div class="content">
				<div class="content-container">
					<!-- 基础机器人 -->
					<div class="lvTitle" v-show="web_type==0">{{$t('robotLevel[0]')}}</div>
					<div class="listbox" v-show="web_type==0">
						<div class="item" v-for="(item,index) in cexRobotList1" :key="index"
							@click="handleOpen(item.robotType,item.limit)"
							:class="{active: activeIndex == item.robotType}">
							<div class="top" :class="{disabled: type < item.limit}">
								<div class="icon">
									<img v-if="theme!='light'"
										:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
									<img v-else
										:src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list.'+item.robotType+'.name')}}
										<i class="iconfont icon-tips" @click.stop="openTip(item.robotType)"></i>
									</span>
								</div>
							</div>
							<div class="btn" v-if="type < item.limit" @click="toRenew">{{$t('common.toOpen')}}</div>
						</div>
					</div>

					<!-- 进阶机器人 -->
					<div class="lvTitle" v-show="web_type==0">{{$t('robotLevel[1]')}}</div>
					<div class="listbox" v-show="web_type==0">
						<div class="item" v-for="(item,index) in cexRobotList2" :key="index"
							@click="handleOpen(item.robotType,item.limit)"
							:class="{active: activeIndex == item.robotType}">
							<div class="top" :class="{disabled: type < item.limit}">
								<div class="icon">
									<img v-if="theme!='light'"
										:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
									<img v-else
										:src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />

									<!-- new标签 -->
									<img class="new" v-if="item.robotType=='hugevol'||item.robotType=='defence'||item.robotType=='open_cex'"
										src="@/assets/images/new.svg" alt="NEW">
								</div>
								<div class="title">
									<span>{{$t('robot.list.'+item.robotType+'.name')}}
										<i class="iconfont icon-tips" @click.stop="openTip(item.robotType)"></i>
									</span>
								</div>
							</div>
							<div class="btn" v-if="type < item.limit" @click="toRenew">{{$t('common.toOpen')}}</div>
						</div>
					</div>

					<!-- 专家机器人 -->
					<div class="lvTitle" v-show="web_type==0">{{$t('robotLevel[2]')}}</div>
					<div class="listbox" v-show="web_type==0">
						<div class="item" v-for="(item,index) in cexRobotList4" :key="index"
							@click="handleOpen(item.robotType,item.limit)"
							:class="{active: activeIndex == item.robotType}">
							<div class="top" :class="{disabled: type < item.limit}">
								<div class="icon">
									<img v-if="theme!='light'"
										:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
									<img v-else
										:src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list.'+item.robotType+'.name')}}
										<i class="iconfont icon-tips" @click.stop="openTip(item.robotType)"></i>
									</span>
								</div>
							</div>
							<div class="btn" v-if="type < item.limit" @click="toRenew">
								{{$t('common.toOpen')}}</div>
						</div>
					</div>

					<!-- dex版 -->
					<div class="lvTitle" v-show="web_type==1">{{$t('level[3]')}}</div>
					<div class="listbox" v-show="web_type==1">
						<div class="item" v-for="(item,index) in robotListDex" :key="index"
							@click="handleOpen(item.robotType,item.limit)"
							:class="{active: activeIndex == item.robotType}">
							<div class="top" :class="{disabled: type < item.limit}">
								<div class="icon">
									<img v-if="theme!='light'"
										:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
									<img v-else
										:src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />

									<!-- new标签 -->
									<img class="new" v-if="item.robotType=='iceberg_dex'" src="@/assets/images/new.svg"
										alt="NEW">
								</div>
								<div class="title">
									<span>
										{{$t('robot.list.'+item.robotType+'.name')}}<i class="iconfont icon-tips"
											@click.stop="openTip(item.robotType)"></i>
									</span>
								</div>
							</div>
							<div class="btn" v-if="type < item.limit" @click="toRenew">{{$t('common.toOpen')}}</div>
						</div>
					</div>

					<!-- NFT版 -->
					<div class="lvTitle" v-show="web_type==2">{{$t('level[4]')}}</div>
					<div class="listbox" v-show="web_type==2">
						<div class="item" v-for="(item,index) in robotListNft" :key="index"
							@click="handleOpen(item.robotType,item.limit)"
							:class="{active: activeIndex == item.robotType}">
							<div class="top" :class="{disabled: type < item.limit}">
								<div class="icon">
									<img v-if="theme!='light'"
										:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
									<img v-else
										:src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list.'+item.robotType+'.name')}}<i class="iconfont icon-tips"
											@click.stop="openTip(item.robotType)"></i></span>
								</div>
							</div>
							<div class="btn" v-if="type < item.limit" @click="toRenew">{{$t('common.toOpen')}}</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<!-- 基础机器人 -->
				<div class="lvTitle" v-show="web_type==0">
					<div>{{$t('robotLevel[0]')}}</div>
					<!-- <div class="btn" v-if="type < 1" @click="toRenew">{{$t('common.open')}}</div> -->
				</div>
				<div class="listbox" v-show="web_type==0">
					<div class="item" v-for="(item,index) in cexRobotList1" :key="index"
						@click="handleOpen(item.robotType,item.limit)" :class="{active: activeIndex == item.robotType}">
						<div class="top" :class="{disabled: type < item.limit}">
							<div class="icon">
								<img v-if="theme!='light'"
									:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
								<img v-else :src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />
							</div>
							<div class="title">
								<span>{{$t('robot.list.'+item.robotType+'.name')}}<i class="iconfont icon-tips"
										@click.stop="openTip(item.robotType)"></i></span>
							</div>
						</div>
					</div>
				</div>

				<!-- 进阶机器人 -->
				<div class="lvTitle" v-show="web_type==0">
					<div>{{$t('robotLevel[1]')}}</div>
					<!-- <div class="btn" v-if="type < 2" @click="toRenew">{{$t('common.open')}}</div> -->
				</div>
				<div class="listbox" v-show="web_type==0">
					<div class="item" v-for="(item,index) in cexRobotList2" :key="index"
						@click="handleOpen(item.robotType,item.limit)"
						:class="{active: activeIndex == item.robotType}">
						<div class="top" :class="{disabled: type < item.limit}">
							<div class="icon">
								<img v-if="theme!='light'"
									:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
								<img v-else :src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />

								<!-- new标签 -->
								<img class="new" v-if="item.robotType=='hugevol'||item.robotType=='defence'||item.robotType=='open_cex'" src="@/assets/images/new.svg"
									alt="NEW">
							</div>
							<div class="title">
								<span>
									{{$t('robot.list.'+item.robotType+'.name')}}
									<i class="iconfont icon-tips" @click.stop="openTip(item.robotType)"></i>
								</span>
							</div>
						</div>
					</div>
				</div>

				<!-- 专家机器人 -->
				<div class="lvTitle" v-show="web_type==0">
					<div>{{$t('level[2]')}}</div>
					<!-- <div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.open')}}</div> -->
				</div>
				<div class="listbox" v-show="web_type==0">
					<div class="item" v-for="(item,index) in cexRobotList4" :key="index"
						@click="handleOpen(item.robotType,item.limit)"
						:class="{active: activeIndex == item.robotType}">
						<div class="top" :class="{disabled: type < item.limit}">
							<div class="icon">
								<img v-if="theme!='light'"
									:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
								<img v-else :src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />
							</div>
							<div class="title">
								<span>{{$t('robot.list.'+item.robotType+'.name')}}<i class="iconfont icon-tips"
										@click.stop="openTip(item.robotType)"></i></span>
							</div>
						</div>
					</div>
				</div>

				<!-- dex版 -->
				<div class="lvTitle" v-show="web_type==1">
					<div>{{$t('level[3]')}}</div>
					<!-- <div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.open')}}</div> -->
				</div>
				<div class="listbox" v-show="web_type==1">
					<div class="item" v-for="(item,index) in robotListDex" :key="index" @click="handleOpen(item.robotType,item.limit)"
						:class="{active: activeIndex == item.robotType}">
						<div class="top" :class="{disabled: type < item.limit}">
							<div class="icon">
								<img v-if="theme!='light'"
									:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
								<img v-else :src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />

								<!-- new标签 -->
								<img class="new" v-if="item.robotType=='iceberg_dex'" src="@/assets/images/new.svg" alt="NEW">
							</div>
							<div class="title">
								<span>{{$t('robot.list.'+item.robotType+'.name')}}<i class="iconfont icon-tips"
										@click.stop="openTip(item.robotType)"></i></span>
							</div>
						</div>
					</div>
				</div>

				<!-- NFT版 -->
				<div class="lvTitle" v-show="web_type==2">
					<div>{{$t('level[4]')}}</div>
					<!-- <div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.open')}}</div> -->
				</div>
				<div class="listbox" v-show="web_type==2">
					<div class="item" v-for="(item,index) in robotListNft" :key="index" @click="handleOpen(item.robotType,item.limit)"
						:class="{active: activeIndex == item.robotType}">
						<div class="top" :class="{disabled: type < item.limit}">
							<div class="icon">
								<img v-if="theme!='light'"
									:src="require('@/assets/images/robot/list_robot-'+item.robotNo+'.png')" />
								<img v-else :src="require('@/assets/images/robot2/list_robot-'+item.robotNo+'.png')" />
							</div>
							<div class="title">
								<span>{{$t('robot.list.'+item.robotType+'.name')}}<i class="iconfont icon-tips"
										@click.stop="openTip(item.robotType)"></i></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

		<!-- 添加弹窗 -->
		<el-dialog :title="robotType?$t('robot.list.'+robotType+'.name'):''" :visible.sync="showAdd" :show-close="false"
			center :before-close="beforeClose">
			<robotForm ref="robotForm" :robotType="robotType" @handleCancel="handleCancel"
				@handleConfirm="handleConfirm(arguments)" @openPop="openPop" :yenUnit1Old.sync="yenUnit1"
				:yenUnit2Old.sync="yenUnit2" :exchange="exchange" :web="web" :isDis="isDis">
			</robotForm>
		</el-dialog>
		<!-- 说明弹窗 -->
		<el-dialog class="w900"
			:title="!!explainIndex?$t('robot.list.'+explainIndex+'.name')+$t('common.explain'):''"
			center :visible.sync="showTips" :show-close="false" @close="explainIndex = null">
			<div class="tips_form"
				v-html="!!explainIndex?$t('robot.list.'+explainIndex+'.html',[yenUnit2]):''"></div>
		</el-dialog>
		<!-- 交易所选择 -->
		<exchange-dialog :show.sync="popExchange" :web="web" @close="closeExchange" @select="handleSelect" />
		<!-- 分拨资金弹窗 -->
		<subTransfer ref="subTransfer" :address_list="address_list" :id_list="id_list" :chain="chain" :token="token"
			:approve_token="approve_token" :web="transferWeb" :exchange="transferExchange"></subTransfer>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'robot.styl';
	@import 'add.styl';
</style>

<script>
	import { Loading } from 'element-ui';

	import ExchangeDialog from '@/components/exchange.vue';
	import robotForm from '@/components/robotForm.vue';
	import subTransfer from '@/components/subTransfer/subTransfer.vue';

	import { mapGetters } from 'vuex';
	import typeFormat from '@/utils/methApi/typeFormat';
	import robotNameFormat from '@/utils/methApi/robotNameFormat';
    import { getTradingPairInfo } from '@/utils/methApi/tradingPairDefault'
	// api
	import { setRobot } from '@/api/robot';
	import { dexWalletsApprove, getRobotList } from '@/api/markets';
	export default ({
		name: 'addrobot',
		components: {
			ExchangeDialog,
			robotForm,
			subTransfer
		},
		data() {
			return {
				showAdd: false,
				showTips: false,
				activeIndex: null,
				robotType: '',// 弹窗选中项
				explainIndex: null,// 说明选中项

				// 交易所选择
				popExchange: false,
				web: getTradingPairInfo('web'),
				// 子组件的交易所
				isSonPop: false,
				web2: null,
				sonFormNum: 0,
				// 一级弹窗
				exchange: getTradingPairInfo('exchange'),
				yenUnit1: getTradingPairInfo('yenUnit1'),
				yenUnit2: getTradingPairInfo('yenUnit2'),
				web_type: getTradingPairInfo('web_type'),

				// 弹窗1是否禁用
				isDis: 0,

				// 资金划分
				address_list: [],
				id_list: [],
				chain: '',
				token: '',
				approve_token: '',
				transferWeb: '',
				transferExchange: '',

				restartLoading: null,//机器人是否正在重启
				resetCountdownTime: 10,//重启倒计时

				// 已创建机器人数量
				robotNum: 0,
				robotBoxList: [],//当前交易所交易对的机器人列表
			}
		},
		computed: {
			...mapGetters([
				'type', 'account', 'theme', 'isMobile', 'telegram',
				'cexRobotList1', 'cexRobotList2', 'cexRobotList4',
				'robotListDex', 'robotListNft'
			]),
		},
		created() {
			let query = this.$route.query
			this.isDis = query.isDis == 1 ? 1 : 0
			if (query.web) {
				this.web = query.web
				this.exchange = query.exchange
				this.yenUnit1 = query.yenUnit1
				this.yenUnit2 = query.yenUnit2
				this.web_type = query.web_type
			}
			this.getList()
		},
		methods: {
			// 获取当前交易所交易对的机器人列表
			async getList() {
				this.loadingSerive = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				if (this.web != 'opensea') {
					var currency = this.yenUnit1 + '_' + this.yenUnit2;
				} else {
					var currency = this.yenUnit1;
				}
				try {
					let res = await getRobotList({
						account: this.account,
						state: '0',
						web: this.web,
						currency
					})
					// console.log(res)
					if (!!res) {
						this.robotNum = res.length
						res.forEach(item => {
							item.robotType = robotNameFormat(item.type, item.sub_type)
						})
						this.robotBoxList = res
					} else {
						this.robotNum = 0
					}
				} catch (error) { }
				this.loadingSerive.close()
			},
			toRenew() {
				this.$router.push({
					path: '/renew'
				})
				// this.$message({
				//     message: this.$t('tip.renewTip'),
				//     type: 'info',
				//     center: true,
				//     customClass: 'shotMsg',
				// });
			},
			openTip(robotType) {
				this.explainIndex = robotType;
				this.showTips = true;
			},
			// 弹窗数据初始化
			init() {
				// 交易所
				// this.web=null;
				this.web2 = null;
				this.sonFormNum = 0;
				// 弹窗2
				if (this.$refs.robotForm) {
					this.$refs.robotForm.init();
				}
			},
			// 打开弹窗
			handleOpen(robotType, limit) {
				if (limit > this.type) {//权限判断
					return;
				} else {
					this.activeIndex = robotType;
					this.robotType = robotType;
					this.toNext()
				}
			},
			beforeClose(done) {
				this.activeIndex = null;
				// 初始化
				this.init()
				done()
			},
			// 获取参数-传递param给组件
			toNext() {
				let targetObject = this.robotBoxList.find(obj => obj.robotType == this.robotType);
				this.showAdd = true;
				if (targetObject) {
					this.$nextTick(() => {
						if (this.$refs.robotForm) {
							this.$refs.robotForm.paramInit(targetObject.state,targetObject.param);
						}
					});
				} else {
					this.$nextTick(() => {
						if (this.$refs.robotForm) {
							this.$refs.robotForm.paramInit(0,'');
						}
					});
				}
			},
			// 弹窗-返回
			handleCancel() {
				this.showAdd = false;
			},
			// 添加、编辑机器人-保存并启动
			handleConfirm(data) {
				var param = data[0];
				var subTypeSelect = data[1];
				var isRestart = data[2];
				var groupId = data[3];
				// console.log('param',param,subTypeSelect)

				var { type, sub_type } = typeFormat(this.robotType, subTypeSelect)

				var currency;
				if (this.web != 'opensea') {
					currency = this.yenUnit1 + '_' + this.yenUnit2
				} else {
					currency = this.yenUnit1
				}
				this.setRobot(param, type, sub_type, currency, isRestart, groupId)
			},
			// 修改机器人参数请求
			setRobot(param, type, sub_type, currency, isRestart, groupId, approve_type) {
				let state = 1

				// 判断是否是需要重启,如果不是直接修改参数
				if (isRestart == 1) {
					// 需要重启，先设置停止机器人并保存
					state = 0
					// 触发重启过程中强制禁止操作方法
					this.restartLoading = Loading.service({
						text: this.$t('tip.restart', { time: this.resetCountdownTime ? '(' + this.resetCountdownTime + ')' : '' }),
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						customClass: 'apploading',
					});
				}
				setRobot({
					web: this.web,
					currency,
					account: this.account,
					type: type,
					sub_type: sub_type,
					param: param,
					state,
					approve_type: approve_type == 'again' ? '0' : '1'
				}).then(res => {
					if (res.code == 2107 || (res.code == 2109 && res.type == 'now')) {
						// 未授权 提示授权中。。
						this.$message({
							message: this.$t('tip.processing'),
							type: 'success',
							center: true,
							customClass: 'shotMsg',
						});
						// 授权代币请求
						dexWalletsApprove({
							web: this.web,
							account: this.account,
							approve_token: res.data.replace(',', '_'),//代币
							is_master_approve: 2,
							group_id: groupId,
						}).then(re => {
							if (re.code == 2172 && re.type == 'confirm') {
								// 分拨授权
								this.address_list = re.data.address_list
								this.id_list = re.data.id_list
								this.chain = re.data.chain
								this.token = re.data.gas_token
								this.approve_token = re.data.approve_token
								this.transferWeb = this.web
								this.transferExchange = this.exchange
								this.$refs.subTransfer.open()
							} else if (re.code == 2172 && re.type == 'ignore') {
								// 忽略，重新操作
								this.setRobot(param, type, sub_type, currency, isRestart, groupId, 'again')
							} else {
								// 授权成功
								this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
									confirmButtonText: this.$t('common.confirm'),//'继续操作',
									// showConfirmButton:false,
									center: true,
									showClose: false,
									customClass: 'deleteOk',
									// cancelButtonText: this.$t('common.back'),
									showCancelButton: false,
								})
							}
						})
					}
					else if (res.code == 2109 && res.type == 'ignore') {
						// 忽略，重新操作
						this.setRobot(param, type, sub_type, currency, isRestart, groupId, 'again')
					}
					else if (res.code == 2109 && res.type == 'confirm') {
						// 分拨授权
						this.address_list = res.data.address_list
						this.id_list = res.data.id_list
						this.chain = res.data.chain
						this.token = res.data.gas_token
						this.approve_token = res.data.approve_token
						this.transferWeb = this.web
						this.transferExchange = this.exchange
						this.$refs.subTransfer.open()
					}
					else {
						// 判断是否是需要重启
						if (isRestart == 1) {
							this.resetCountdownTime = 10
							// 10s后重启-倒计时
							let resetCountdown = setInterval(() => {
								if (this.resetCountdownTime > 0) {
									this.resetCountdownTime--
									this.restartLoading.setText(this.$t('tip.restart', { time: this.resetCountdownTime ? '(' + this.resetCountdownTime + ')' : '' }))
								} else {
									clearTimeout(resetCountdown)
								}
							}, 1000)
							// 10s后重启
							let robotTimeReset = setTimeout(() => {
								this.setRobot(param, type, sub_type, currency, 0, groupId)
							}, 10000)
							this.$once('hook:beforeDestroy', function () {
								clearInterval(resetCountdown)
								clearTimeout(robotTimeReset)
							});
						} else {
							this.getList()
							// 不需要重启或者是重启已完成，关闭重启过程中强制禁止操作方法，
							this.restartLoading?.close();

							// 并初始化
							this.showAdd = false;
							// 提交数据成功后
							this.$nextTick(() => {
								if (this.robotNum == 0 && !this.telegram) {
									this.robotNum = 1
									this.$confirm(this.$t('tip.notBindTG'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.yes'),
										center: true,
										showClose: false,
										customClass: 'deleteOk',
										cancelButtonText: this.$t('common.no'),
									}).then(res => {
										window.location.href = 'https://t.me/lightning_mm_bot'
									})
								}
								this.$message({
									message: this.$t('tip.addSuc'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});

								// 提交成功初始化
								this.activeIndex = null;
								// 初始化
								this.init()
							})
						}
					}
				})
			},

			// 交易所-仅关闭触发
			closeExchange() {
				this.popExchange = false;
				// 子组件判定初始化
				if (this.isSonPop) {
					this.isSonPop = false;
					// 还原交易所数据
					this.web = this.web2;
					this.web2 = null;
				}
			},
			handleSelect(data) {
				if (this.isSonPop) {
					if (this.sonFormNum == 'web') {
						// 主交易所
						this.exchange = data.text;
						this.web = data.web;
						this.web_type = data.web_type
						// 子组件判定初始化
						this.isSonPop = false;
						this.popExchange = false;
						this.$refs.robotForm.getWalletGroupList()
					} else {
						this.$refs.robotForm.handleSelect(data, this.sonFormNum)
						// 子组件判定初始化
						this.isSonPop = false;
						this.popExchange = false;
						// 还原交易所数据
						this.web = this.web2;
						this.web2 = null;
					}
				} else {
					this.exchange = data.text;
					this.web = data.web;
					this.web_type = data.web_type;
					this.popExchange = false;
				}
			},
			// 子组件交易所
			openPop(data, num) {
				// 暂存原交易所数据
				this.web2 = this.web;
				// 子组件交易所数据展示
				this.web = data.web;

				this.isSonPop = true;
				this.popExchange = true;
				if (data.num) {
					this.sonFormNum = data.num;
				}
			},
		}
	})
</script>